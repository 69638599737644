import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UrlParams } from '../../enums/url-params.enum';
import { FeatureFlagService } from '../../modules/feature-flag/feature-flag.service';
import {
  EventData,
  EventDataParams,
  EventDataResult,
  GetCameraParams,
  TryGetDataFromPositioningTools,
} from '../../types/jsonrpc.interface';
import { BackendEventsService } from '../backend-events.service';
import { CameraParameters } from './cameraparameters.type';

/**
 * Сервис работы с настройками камеры.
 */
@Injectable({ providedIn: 'root' })
export class CameraService {
  /**
   * Максимальная высота камеры.
   */
  static readonly MAX_HEIGHT = 2_000_000_000;

  /**
   * Событие смены параметров камеры.
   */
  readonly cameraParamsEvent$: Observable<CameraParameters>;

  /**
   * Изначальные параметры камеры.
   */
  readonly initCameraParameters: CameraParameters = {
    isSystem: false,
    altMetrToSurface: 2000,
    compasRotation: 0,
    pitchAngle: 0,
    northCoordinates: '',
    westCoordinates: '',
    tilesPercent: 0,
    onMapDistanceTrace: 0,
  };

  /**
   * Параметры камеры.
   */
  readonly cameraParams: Signal<CameraParameters>;

  /**
   * Сигнал для параметров камеры.
   */
  #cameraParamsSignal: WritableSignal<CameraParameters> = signal(this.initCameraParameters);

  /**
   * Конструктор.
   * @param backendEventsService Сервис работы с backend.
   * @param featureFlagService Сервис работы с флагами фич.
   * @param router Роутинг.
   * @param activatedRoute Активный роутинг.
   */
  constructor(
    private backendEventsService: BackendEventsService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.cameraParams = this.#cameraParamsSignal.asReadonly();
    this.cameraParamsEvent$ = toObservable(this.cameraParams);
  }

  /**
   * Возвращает признак, что функциональность компаса включена.
   * @returns Признак, что функциональность компаса включена.
   */
  isFeatureCompassOn(): boolean {
    return this.featureFlagService.isFeatureOn('COMPASS');
  }

  /**
   * Получает данные от сервера и применяет их.
   */
  getCameraParams(): Observable<
    EventData<{
      result: TryGetDataFromPositioningTools;
    }>
  > {
    return this.backendEventsService.tryGetDataFromPositioningTools();
  }

  /**
   * Установка параметров камеры.
   * @param cameraParams Параметры камеры.
   */
  setCameraParams(cameraParams: GetCameraParams | TryGetDataFromPositioningTools | undefined): void {
    if (!this.isFeatureCompassOn()) {
      return;
    }

    if (!cameraParams) {
      return;
    }

    const height = this.limitHeight(cameraParams.altMetrToSurface);

    this.#cameraParamsSignal.set({
      isSystem: true,
      altMetrToSurface: height,
      compasRotation: cameraParams.compasRotation,
      pitchAngle: cameraParams.pitchAngle,
      northCoordinates: cameraParams.northCoordinates,
      westCoordinates: cameraParams.westCoordinates,
      tilesPercent: cameraParams.tilesPercent,
      onMapDistanceTrace: cameraParams.onMapDistanceTrace,
    });

    this.saveCameraParams(cameraParams);
  }

  /**
   * Устанавливает угол наклона камеры.
   * @param pitchAngle Угол наклона.
   */
  setPitchAngle(pitchAngle: number): void {
    if (!this.featureFlagService.isFeatureOn('COMPASS')) {
      return;
    }

    const cameraParams = this.cameraParams();
    this.#cameraParamsSignal.set({
      isSystem: false,
      altMetrToSurface: cameraParams.altMetrToSurface,
      compasRotation: cameraParams.compasRotation,
      pitchAngle,
      northCoordinates: cameraParams.northCoordinates,
      westCoordinates: cameraParams.westCoordinates,
      tilesPercent: cameraParams.tilesPercent,
      onMapDistanceTrace: cameraParams.onMapDistanceTrace,
    });
  }

  /**
   * Устанавливает угол поворота камеры.
   * @param yawAngle Угол поворота.
   */
  setYawAngle(yawAngle: number): void {
    if (!this.featureFlagService.isFeatureOn('COMPASS')) {
      return;
    }

    const cameraParams = this.cameraParams();
    this.#cameraParamsSignal.set({
      isSystem: false,
      altMetrToSurface: cameraParams.altMetrToSurface,
      compasRotation: yawAngle,
      pitchAngle: cameraParams.pitchAngle,
      northCoordinates: cameraParams.northCoordinates,
      westCoordinates: cameraParams.westCoordinates,
      tilesPercent: cameraParams.tilesPercent,
      onMapDistanceTrace: cameraParams.onMapDistanceTrace,
    });
  }

  /**
   * Устанавливает высоту камеры.
   * @param height высота.
   */
  setHeight(height: number): void {
    if (!this.featureFlagService.isFeatureOn('COMPASS_CAMERA_HEIGHT')) {
      return;
    }

    height = this.limitHeight(height);

    const cameraParams = this.cameraParams();
    this.#cameraParamsSignal.set({
      isSystem: false,
      altMetrToSurface: height,
      compasRotation: cameraParams.compasRotation,
      pitchAngle: cameraParams.pitchAngle,
      northCoordinates: cameraParams.northCoordinates,
      westCoordinates: cameraParams.westCoordinates,
      tilesPercent: cameraParams.tilesPercent,
      onMapDistanceTrace: cameraParams.onMapDistanceTrace,
    });
  }

  /** Отправка запроса на приближение камеры. */
  zoomIn(): Observable<
    EventData<{
      params?: EventDataParams;
      result?: EventDataResult;
    }>
  > {
    return this.backendEventsService.sendZoomInEvent();
  }

  /** Отправка запроса на отдаление камеры. */
  zoomOut(): Observable<
    EventData<{
      params?: EventDataParams;
      result?: EventDataResult;
    }>
  > {
    return this.backendEventsService.sendZoomOutEvent();
  }

  /**
   * Сохранение параметров камеры.
   * @param cameraParams Параметры камеры.
   */
  private saveCameraParams(cameraParams: GetCameraParams): void {
    this.#cameraParamsSignal.set({
      isSystem: true,
      altMetrToSurface: cameraParams.altMetrToSurface,
      compasRotation: cameraParams.compasRotation,
      pitchAngle: cameraParams.pitchAngle,
      northCoordinates: cameraParams.northCoordinates,
      westCoordinates: cameraParams.westCoordinates,
      tilesPercent: cameraParams.tilesPercent,
      onMapDistanceTrace: cameraParams.onMapDistanceTrace,
    });

    this.setCameraParamsToUrl(cameraParams);
  }

  /**
   * Устанавливает параметры камеры в URL.
   * @param cameraParams Параметры камеры.
   */
  private setCameraParamsToUrl(cameraParams: GetCameraParams): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        [UrlParams.HEIGHT]: cameraParams.altMetrToSurface,
        [UrlParams.YAW]: cameraParams.compasRotation,
        [UrlParams.PITCH]: cameraParams.pitchAngle,
      },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Ограничивает высоту.
   * @param height Текущая высота.
   * @returns Ограниченная высота.
   */
  private limitHeight(height: number): number {
    if (height > CameraService.MAX_HEIGHT) {
      height = CameraService.MAX_HEIGHT;
    }

    return height;
  }
}
